import React, { useState, useEffect, useRef } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import CircularProgress from '@mui/material/CircularProgress'
import { CheckCircle } from '@mui/icons-material'

export default function Calculating(props) {
    const [calculating1, setCalculating1] = useState(true)
    const [calculating2, setCalculating2] = useState(true)
    //const [calculating3, setCalculating3] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setCalculating1(false)
        }, 3000)
        setTimeout(() => {
            setCalculating2(false)
        }, 6000)
        setTimeout(() => {
            //props.setStage(2)
            //console.log('redirect: ', props.redirectUrl)
            //window.parent.location.replace(props.redirectUrl)
        }, 7000)
    }, [])

    return (
        <Grid container spacing={2} justifyContent='center' alignItems='center' style={{height: '50%'}}>
            <Grid xs={12} display='flex' justifyContent='center' alignItems='center'> 
                {calculating2 ? (
                    <CircularProgress />
                ) : (
                    <CheckCircle sx={{ fontSize: '50px' }} color='primary' />
                )}
            </Grid>
            <Grid xs={12} display='flex' justifyContent='center' alignItems='center' style={{textAlign: 'center'}}>
                {calculating1 &&
                    <h3>Processing your information...</h3>
                }
                {!calculating1 &&
                    <h3>{props.message}</h3>
                }
            </Grid>
        </Grid>
    )
}