import React, { useState, useRef } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import MostImportant from './Questions/MostImportant'
import HowMuch from './Questions/HowMuch'
import Timeline from './Questions/Timeline'
import DiverterOrBattery from './Questions/DiverterOrBattery'
import ElectricBills from './Questions/ElectricBills'
import Stories from './Questions/Stories'
import RoofType from './Questions/RoofType'
import GrantInterest from './Questions/GrantInterest'
import Eircode from './Questions/Eircode'
import Name from './Questions/Name'
import Email from './Questions/Email'
import Phone from './Questions/Phone'
import PrivacyPolicy from './Questions/PrivacyPolicy'
import Invalid from './Invalid'
import Calculating from './Calculating'
import ProgressBar from 'react-bootstrap/ProgressBar'
import * as api from '../config/api/GuestAPI'

const wait = t => new Promise((resolve, reject) => setTimeout(resolve, t))

export default function DataCaptureForm(props) {
    const [stage, setStage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [invalid, setInvalid] = useState()
    const [invalidReason, setInvalidReason] = useState('')
    // solar details
    const [mostImportant, setMostImportant] = useState('')
    const [howMuch, setHowMuch] = useState('')
    const [timeline, setTimeline] = useState('')
    const [diverterOrBattery, setDiverterOrBattery] = useState('')
    const [electricBills, setElectricBills] = useState('')
    const [stories, setStories] = useState('')
    const [roofType, setRoofType] = useState('')
    const [hasGrantInterest, setHasGrantInterest] = useState('')
    // personal details
    const [eircode, setEircode] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [privacyPolicy, setPrivacyPolicy] = useState('')

    const dcForm = useRef(null)

    async function submitForm(e) {
        setLoading(true)
        if (e) {
            e.preventDefault()
        }
        const details = {
            mostImportant,
            howMuch,
            timeline,
            diverterOrBattery,
            electricBills,
            stories,
            roofType,
            hasGrantInterest,
            eircode,
            firstName,
            lastName,
            email,
            phone,
            privacyPolicy: true
        }
        console.log('submit details: ', details)
        
        try {
            console.log('hitting add lead api...')
            let response = await api.submitForm(details)
            console.log('repsonse: ', response)
            // add delay
            await wait(7000)
            if (response.isValid) {
                let provider = response.companyId ? response.companyId.toLowerCase() : ''
                console.log('provider: ', provider)
                window.parent.location.replace('https://energyefficiency.ie/solar-recommendation?p=' + provider)
            }
            else {
                if (response.invalidReason === 'Profanity' || response.invalidReason === 'Fake Name' || response.invalidReason === 'Famous Name') {
                    await api.sendTrollDetails(details.firstName, details.lastName, details.email, details.phone, response.invalidReason)
                    //console.log('response: ', resp)
                    window.parent.location.replace('https://youtu.be/GM-e46xdcUo?t=28')
                }
                else {
                    setInvalid(true)
                    if (response.invalidReason === 'Phone number prefix' || response.invalidReason === 'Phone number repeat' || response.invalidReason === 'Phone number sequential') {
                        setInvalidReason('Phone')
                    }
                    else if (response.invalidReason === 'Duplicate in last 90 days') {
                        setInvalidReason('Duplicate')
                    }
                    setLoading(false)
                }
            }
        } catch(e) {
            console.log('Error submitting form: ', e)
        }
    }

    function updateStage(newStage) {
        setStage(newStage)
        //dcForm.current.scrollIntoView()
    }

    function checkNextDisabled() {
        if (stage === 0) {
            return !mostImportant
        }
        if (stage === 1) {
            return !howMuch
        }
        if (stage === 2) {
            return !electricBills
        }
        if (stage === 3) {
            return !timeline
        }
        if (stage === 4) {
            return !diverterOrBattery
        }
        if (stage === 5) {
            return !stories
        }
        if (stage === 6) {
            return !roofType
        }
        if (stage === 7) {
            return !hasGrantInterest
        }
        if (stage === 8) {
            return !eircode
        }
        if (stage === 9) {
            return !firstName || !lastName
        }
        if (stage === 10) {
            return !email
        }
        if (stage === 11) {
            return !phone
        }
        if (stage === 12) {
            return !privacyPolicy
        }
    }

    return (
        <>
        {loading ? (
            <Grid container justifyContent='flex-start'>
                <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                        <Calculating 
                            message='Finding the best solar installer in your area...'
                        />
                    </Box>
                </Grid>
            </Grid>
        ) : (
            <>
            {invalid ? (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <Invalid 
                                reason={invalidReason}
                            />
                        </Box>
                    </Grid>
                </Grid>
            ): (
            <form ref={dcForm} onSubmit={(e) => e.preventDefault()} style={{height: '100vh', padding: '2%'}}>
                <Grid container justifyContent='center' className='progress-bar-container scroll-container'>
                    <Grid xs={11}>
                        <ProgressBar animated variant='success' now={((stage + 1) / 12) * 100} />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' className='question-container scroll-container'>
                    <Grid xs={10} className='question'>
                        {stage === 0 &&
                            <MostImportant 
                                answer={mostImportant}
                                updateAnswer={(mostImportant) => setMostImportant(mostImportant)}
                                nextStage={() => updateStage(stage + 1)}
                                site='energyefficiency'
                            />
                        }
                        {stage === 1 &&
                            <ElectricBills 
                                answer={electricBills}
                                updateAnswer={(electricBills) => setElectricBills(electricBills)}
                                nextStage={() => updateStage(stage + 1)}
                                site='energyefficiency'
                            />
                        }
                        {stage === 2 &&
                            <HowMuch 
                                answer={howMuch}
                                updateAnswer={(howMuch) => setHowMuch(howMuch)}
                                nextStage={() => updateStage(stage + 1)}
                                electricBills={electricBills}
                                site='energyefficiency'
                            />
                        }
                        {stage === 3 &&
                            <Timeline 
                                answer={timeline}
                                updateAnswer={(timeline) => setTimeline(timeline)}
                                nextStage={() => updateStage(stage + 1)}
                                site='energyefficiency'
                            />
                        }
                        {stage === 4 &&
                            <DiverterOrBattery 
                                answer={diverterOrBattery}
                                updateAnswer={(diverterOrBattery) => setDiverterOrBattery(diverterOrBattery)}
                                nextStage={() => updateStage(stage + 1)}
                                site='energyefficiency'
                            />
                        }
                        {stage === 5 &&
                            <Stories 
                                answer={stories}
                                updateAnswer={(stories) => setStories(stories)}
                                nextStage={() => updateStage(stage + 1)}
                                site='energyefficiency'
                            />
                        }
                        {stage === 6 &&
                            <RoofType 
                                answer={roofType}
                                updateAnswer={(roofType) => setRoofType(roofType)}
                                nextStage={() => updateStage(stage + 1)}
                                site='energyefficiency'
                            />
                        }
                        {stage === 7 &&
                            <GrantInterest 
                                answer={hasGrantInterest}
                                updateAnswer={(hasGrantInterest) => setHasGrantInterest(hasGrantInterest)}
                                nextStage={() => updateStage(stage + 1)}
                                site='energyefficiency'
                            />
                        }
                        {stage === 8 &&
                            <Eircode 
                                eircode={eircode}
                                setEircode={(eircode) => setEircode(eircode)}
                                nextStage={() => updateStage(stage + 1)}
                                site='energyefficiency'
                            />
                        }
                        {stage === 9 &&
                            <Name 
                                firstName={firstName}
                                lastName={lastName}
                                setFirstName={(firstName) => setFirstName(firstName)}
                                setLastName={(lastName) => setLastName(lastName)}
                                nextStage={() => updateStage(stage + 1)}
                                site='energyefficiency'
                            />
                        }
                        {stage === 10 &&
                            <Email 
                                email={email}
                                setEmail={(email) => setEmail(email)}
                                nextStage={() => updateStage(stage + 1)}
                                site='energyefficiency'
                            />
                        }
                        {stage === 11 &&
                            <Phone 
                                phone={phone}
                                setPhone={(phone) => setPhone(phone)}
                                nextStage={() => updateStage(stage + 1)}
                                site='energyefficiency'
                            />
                        }
                        {stage === 12 &&
                            <PrivacyPolicy 
                                privacyPolicy={privacyPolicy}
                                setPrivacyPolicy={(privacyPolicy) => setPrivacyPolicy(privacyPolicy)}
                                agree={() => submitForm()}
                                site='energyefficiency'
                            />
                        }
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' className='bottom-container scroll-container'>
                    <Grid xs={11} display='flex' justifyContent={stage > 0 ? 'space-between' : 'flex-end'} alignItems='center' className='buttons'>
                        {stage > 0 && 
                            <Button variant='outlined' size='large' onClick={() => updateStage(stage - 1)}>Back</Button>
                        }
                        {stage < 12 && 
                            <Button disabled={checkNextDisabled()} variant='contained' size='large' onClick={() => updateStage(stage + 1)}>Next</Button>
                        }
                        {stage === 12 &&
                            <Button variant='contained' size='large' onClick={() => submitForm()}>I Agree</Button>
                        }
                    </Grid>
                </Grid>
            </form>
            )}
            </>
        )}
        </>
    )
}