export function getColor(site) {
    if (site === 'energyefficiency') {
        return 'success'
    }
    else if (site === 'solarfinder') {
        return 'warning'
    }
    else if (site === 'iyi') {
        return 'warning'
    }
    else {
        return 'success'
    }
}