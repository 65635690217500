import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import { getColor } from '../../config/helpers/Helper'

export default function Name(props) {
    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>What's your name?</h2>
                <p className='question-subtext hide'>Hide</p>
            </Grid>
            <Grid xs={12} sm={6}>
                <TextField 
                    //label='Last Name' 
                    color={getColor(props.site)} 
                    variant='outlined' 
                    type='text'
                    value={props.firstName} 
                    placeholder='First Name'
                    size='large'
                    onChange={(e) => props.setFirstName(e.target.value)} 
                    style={{width: '100%'}}
                    inputProps = {
                        {
                            sx: {
                                '&::placeholder': {
                                    color: 'rgb(121, 118, 118)',
                                    opacity: 1
                                },
                            },
                        }
                    }
                />
            </Grid>
            <Grid xs={12} sm={6}>
                <TextField 
                    //label='Last Name' 
                    color={getColor(props.site)} 
                    variant='outlined' 
                    type='text'
                    value={props.lastName} 
                    placeholder='Last Name'
                    size='large'
                    onChange={(e) => props.setLastName(e.target.value)} 
                    style={{width: '100%'}}
                    inputProps = {
                        {
                            sx: {
                                '&::placeholder': {
                                    color: 'rgb(121, 118, 118)',
                                    opacity: 1
                                },
                            },
                        }
                    }
                />
            </Grid>
        </Grid>
    )
}