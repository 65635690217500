import axios from 'axios'

export async function submitForm(data) {
    try {
        const response = await axios({
            method: 'post',
            //url: 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/energy/add-solar',
            url: process.env.NODE_ENV === 'development' ? 'https://ckuu0r9li1.execute-api.eu-west-1.amazonaws.com/dev/energy/add-solar' : 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/energy/add-solar',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {...data, site: 'ee'}
        })
        console.log(response)
        return response.data
    } catch (e) {
        console.error(e)
        return 'error'
    }
}

export async function submitSolarfinderForm(data) {
    try {
        const response = await axios({
            method: 'post',
            //url: 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/energy/add-solar',
            url: process.env.NODE_ENV === 'development' ? 'https://ckuu0r9li1.execute-api.eu-west-1.amazonaws.com/dev/energy/add-solar' : 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/energy/add-solar',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {...data, site: 'sf', source: 'solarfinder.ie'}
        })
        console.log(response)
        return response.data
    } catch (e) {
        console.error(e)
        return 'error'
    }
}

export async function submitSolarfinderQuotesForm(data) {
    try {
        const response = await axios({
            method: 'post',
            //url: 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/energy/add-solar',
            url: process.env.NODE_ENV === 'development' ? 'https://ckuu0r9li1.execute-api.eu-west-1.amazonaws.com/dev/energy/add-solar' : 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/energy/add-solar',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {...data, site: 'sf', source: 'quotes.solarfinder.ie'}
        })
        console.log(response)
        return response.data
    } catch (e) {
        console.error(e)
        return 'error'
    }
}

export async function sendTrollDetails(firstName, lastName, email, phone, invalidReason) {
    let data = {
        firstName,
        lastName,
        email,
        phone,
        invalidReason
    }
    console.log('data: ', data)
    try {
        const response = await axios({
            method: 'POST',
            url: 'https://hooks.zapier.com/hooks/catch/3396517/2yc2lb6',
            data: JSON.stringify(data)
        })
        console.log(response)
        return 'success'
    } catch (e) {
        console.error(e)
        return 'error'
    }
}

export async function sendSmsConfirmation(phone, site) {
    try {
        const response = await axios({
            method: 'post',
            //url: 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/energy/add-solar',
            url: process.env.NODE_ENV === 'development' ? 'https://ah7cxw1aa6.execute-api.eu-west-1.amazonaws.com/dev/verification/send-confirmation' : 'https://c9rtj2s6a4.execute-api.eu-west-1.amazonaws.com/prod/verification/send-confirmation',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {phone, site}
        })
        console.log(response)
        return response.data
    } catch (e) {
        console.error(e)
        return 'error'
    }
}

export async function checkConfirmationCode(phone, code, site) {
    try {
        const response = await axios({
            method: 'post',
            //url: 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/energy/add-solar',
            url: process.env.NODE_ENV === 'development' ? 'https://ah7cxw1aa6.execute-api.eu-west-1.amazonaws.com/dev/verification/confirm' : 'https://c9rtj2s6a4.execute-api.eu-west-1.amazonaws.com/prod/verification/confirm',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {phone, code, site}
        })
        console.log(response)
        return response.data
    } catch (e) {
        console.error(e)
        return 'error'
    }
}

export async function submitIyiForm(data) {
    try {
        const response = await axios({
            method: 'post',
            //url: 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/energy/add-solar',
            url: process.env.NODE_ENV === 'development' ? 'https://ckuu0r9li1.execute-api.eu-west-1.amazonaws.com/dev/leads/add-income-protection-quarantine' : 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/leads/add-income-protection-quarantine',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {...data, source: 'quotes.insureyourincome.ie'}
        })
        console.log(response)
        return response.data
    } catch (e) {
        console.error(e)
        return 'error'
    }
}

export async function submitIyiQuotesForm(data) {
    try {
        const response = await axios({
            method: 'post',
            //url: 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/energy/add-solar',
            url: process.env.NODE_ENV === 'development' ? 'https://ckuu0r9li1.execute-api.eu-west-1.amazonaws.com/dev/leads/add-income-protection-quarantine' : 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/leads/add-income-protection-quarantine',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {...data, source: 'insureyourincome.ie'}
        })
        console.log(response)
        return response.data
    } catch (e) {
        console.error(e)
        return 'error'
    }
}

export async function getRiskLevel(occupation) {
    try {
        const response = await axios({
            method: 'post',
            //url: 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/energy/add-solar',
            url: process.env.NODE_ENV === 'development' ? 'https://ckuu0r9li1.execute-api.eu-west-1.amazonaws.com/dev/gpt/get-risk-level' : 'https://816sg9yfs0.execute-api.eu-west-1.amazonaws.com/prod/gpt/get-risk-level',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {occupation}
        })
        console.log(response)
        return response.data.risk
    } catch (e) {
        console.error(e)
        return 'error'
    }
}